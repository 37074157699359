import React from "react";

function Index() {
    return (
        <div
            className=""
            id="contact"
            style={{
                backgroundColor: " rgb(255 238 208)",
            }}
        >
            <div className="container pt-5">
                <div className="row mb--50">
                    <div className="col-6 col-lg-2 logo">
                        <div className="footer-widget">
                            <a href="index.html" style={{ padding: "7px 0" }}>
                                <img
                                    src="images/newImages/DiscoverOakSchoolFinaL.png"
                                    className="img-fluid"
                                    alt="logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="footer-widget widget-about">
                            <h5 className="title">WORKING HOURS</h5>
                            <ul className="contact">
                                <li>
                                    <i className="far fa-clock"></i> Office Hours
                                </li>
                                <li> Mon-Fri: 09:00 am – 5:00 pm </li>
                                <li>Sat: 09:00 am - 1:00 pm </li>
                                <li>
                                    <i className="far fa-clock"></i> School Timings{" "}
                                </li>
                                <li> Mon - Sat: 09:00 am - 2:30 pm</li>
                            </ul>
                            <ul className="social-icons">
                                <li>
                                    <a
                                        href="https://www.facebook.com/discoverioakspublicschool?mibextid=ZbWKwL"
                                        target="_blank"
                                        className="facebook"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank" className="instagram">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="footer-widget widget-blog">
                            <h5 className="title">CONTACT US</h5>
                            <ul className="footer-blog me-0">
                                <li className="mb-0 pb-1">
                                    <div className="content ps-0">
                                        <span>
                                            Road #1, Sai Ram Nagar Colony, <br /> karmanghat,
                                            Hyderabad, Telangana <br />
                                            500079
                                        </span>
                                    </div>
                                </li>
                                <li className="mb-0 pb-0">
                                    <div className="content ps-0">
                                        <a href="#" style={{ color: "#92278f" }}>
                                            <i className="fas fa-phone-alt"></i>
                                            +91-81064 63683
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div className="content ps-0">
                                        <a
                                            href="mailto:discoverioakspublicschool@gmail.com"
                                            style={{ color: "#92278f" }}
                                        >
                                            <i className="fa-regular fa-envelope"></i>{" "}
                                            discoverioakspublicschool@gmail.com
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="footer-widget widgt-form">
                            <h5 className="title">LOCATION</h5>
                            <div style={{ width: "100%" }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3808.4714395803503!2d78.5211503!3d17.3410334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9972c769a77f%3A0xad90cd9178e67bd8!2sDiscoveri%20Oaks%20Public%20School!5e0!3m2!1sen!2sin!4v1691158641856!5m2!1sen!2sin"
                                    width="100%"
                                    height="250"
                                    style={{ border: "0" }}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
