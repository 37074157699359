import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { selectForm } from "./selector";
import { SendEmail } from "../utils/request";
import { DataInterface } from "./types";
import { toast } from "react-toastify";

export function* doSendEmailRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: DataInterface = yield select(selectForm);
  if (form.name.length == 0) {
    toast.error("please enter name");
    return;
  }
  if (form.childName.length == 0) {
    toast.error("please enter child name");
    return;
  }
  if (form.dateOfBirth.length == 0) {
    toast.error("please select dob ");
    return;
  }
  if (form.email.length == 0) {
    toast.error("please enter email");
    return;
  }
  if (form.mobileNumber.length == 0) {
    toast.error("please enter phone number");
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(SendEmail, form);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.message !== "e is undefined") {
      if (
        error.message !== "undefined is not an object (evaluating 'e.context')"
      ) {
        if (
          error.message !==
          "Cannot read properties of undefined (reading 'context')"
        ) {
          if (
            error.message !==
            "Cannot read properties of undefined (reading '0')"
          ) {
            if (error.message !== "call: argument fn is undefined or null") {
              if (
                error.message !==
                "Cannot read properties of undefined (reading 'results')"
              ) {
                if (error.response) {
                  if (error.response.status !== 401) {
                    if (error.response.data.errors) {
                      let dataerrer = error.response.data.errors.map(
                        (item: any) => {
                          return {
                            name: item.property,
                            message: item.constraints.matches,
                          };
                        }
                      );
                      for (let index = 0; index < dataerrer.length; index++) {
                        const element = dataerrer[index];
                        toast.error(element.message);
                      }
                      let dataerrer1 = error.response.data.errors.map(
                        (item: any) => {
                          return {
                            name: item.property,
                            message: item.constraints.isEmail,
                          };
                        }
                      );
                      for (let index = 0; index < dataerrer1.length; index++) {
                        const element = dataerrer1[index];
                        toast.error(element.message);
                      }
                      let dataerrer2 = error.response.data.errors.map(
                        (item: any) => {
                          return {
                            name: item.property,
                            message: item.constraints.isString,
                          };
                        }
                      );
                      for (let index = 0; index < dataerrer2.length; index++) {
                        const element = dataerrer2[index];
                        toast.error(element.message);
                      }
                      let dataerrer3 = error.response.data.errors.map(
                        (item: any) => {
                          return {
                            name: item.property,
                            message: item.constraints.isNotEmpty,
                          };
                        }
                      );
                      for (let index = 0; index < dataerrer2.length; index++) {
                        const element = dataerrer3[index];

                        toast.error(element.message);
                      }
                    } else {
                      toast.error(error.response.data.message);
                    }
                  } else {
                    localStorage.removeItem("token");
                  }
                } else {
                  toast.error(error.message);
                }
              }
            }
          }
        }
      }
    }
  }
}

export function* MainStateRepoSaga() {
  yield takeLatest(actions.doSendEmail, doSendEmailRequest);
}
