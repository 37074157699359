import React from 'react'

function Index() {
    return (
        <div>  <section className="speaker-single-section padding-top padding-bottom" id="leadership">
            <div className="container">

                <div className="section-header">
                    <h3 className="title text-center pb-3" style={{ color: "#92278f" }} >LEADERSHIP</h3>

                </div>
                <div className="teacher-wrapper pb-1 pt-4" style={{ borderRadius: "20px" }}>
                    <div className="square2 "></div>
                    <div className="teacher-single-top">
                        <div className="teacher-single-item d-flex flex-wrap justify-content-between">
                            <div></div>
                            <div className="teacher-single-thumb ">
                                <img src="images/leaderShip.jpg" alt="teacher" />
                            </div>

                            <div className="teacher-single-content">
                                <div className="square21 d-block d-lg-none"></div>
                                <h4 className="title" style={{ color: "blue" }} >Pramod Karnati</h4>
                                <h4 className="title" style={{ fontSize: "medium" }}>Director</h4>
                                <p className="header-p">
                                    With a wealth of experience and deep understanding of the unique needs of young learners, we are
                                    committed to provide a nurturing and enriching environment for your child to flourish. As an
                                    accomplished educationalist, holding an engineering and Business Management degree, driving With vision
                                    to create a dynamic and inclusive learning community, where each child's potential is celebrated and
                                    their love for learning is ignited.
                                </p>
                                <h6 className="subtitle">Personal Statement</h6>
                                <p className="header-p">
                                    We believe it is the quality and range of learning that enriches opportunities. We offer it to help and
                                    ensure every student gets the opportunity to reach their greatest potential. We invite you to discover
                                    yourself at Discoveri Oaks Public School.
                                </p>


                            </div>
                        </div>
                        <div className="square21 d-none d-lg-block"></div>
                    </div>

                </div>
            </div>
        </section></div>
    )
}

export default Index