import React from "react";

function index() {
  return (
    <div>
      <div className="counter-section padding-bottom bg_img" id="whyus">
        <div className="container">
          <div className="section-header">
            <h3
              className="title my-3 text-center pb-4"
              style={{ color: "#92278f", textTransform: "none" }}
            >
              Why Parents Choose Discoveri Oaks Public School
            </h3>
          </div>
          <div className="row justify-content-center mb-30-none">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="counter-item">
                <div className="counter-thumb">
                  <img
                    src="images/newImages/gallery/franchise/teamwork.png"
                    alt="counter"
                    className="img-fluid"
                  />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">Stem Activities</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="counter-item">
                <div className="counter-thumb">
                  <img
                    src="images/newImages/gallery/franchise/classroom_3197877.png"
                    alt="counter"
                    className="img-fluid"
                  />
                </div>
                <div className="counter-header">
                  <h2 className="title">Infrastructure Design</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="counter-item">
                <div className="counter-thumb">
                  <img
                    src="images/newImages/gallery/franchise/concept_1690967.png"
                    alt="counter"
                    className="img-fluid"
                  />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">Think Room</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-30-none mt-4">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="counter-item">
                <div className="counter-thumb">
                  <img
                    src="images/newImages/gallery/franchise/refugee_7681410.png"
                    alt="counter"
                    className="img-fluid"
                  />
                </div>
                <div className="counter-header">
                  <h2 className="title">Adult Child Ratio</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="counter-item">
                <div className="counter-thumb">
                  <img
                    src="images/newImages/gallery/franchise/teacher_8065344.png"
                    alt="counter"
                    className="img-fluid"
                  />
                </div>
                <div className="counter-header">
                  <h2 className="title">Trained Staff</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="counter-item">
                <div className="counter-thumb">
                  <img
                    src="images/newImages/gallery/franchise/diet_706164.png"
                    alt="counter"
                    className="img-fluid"
                  />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">100% Nutrition Food</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
