import axios from "axios";
import Api from "./Api";

const SendEmail = async (data: any) => {
  return Api.post(`/student/admission-email`, data);
};

export {
  SendEmail,
};
