import React from "react";
import Accrodian from "./Accrodian";

function Index() {
    return (
        <div className="padding-top about-bottom" id="about">
            <div className="container" id="about">
                <div className="square2 "></div>
                <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className=" pr-4 square">
                            <img
                                src="images/newImages/aboutPage.png"
                                alt="about"
                                className="img-fluid "
                            />
                        </div>
                        <div className="square21 d-block d-lg-none"></div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header left-style mb-olpo">
                                <h3 className="title pb-3" style={{ color: "#92278f" }}>
                                    ABOUT US
                                </h3>
                                <p>
                                    Welcome to Discoveri Oaks Public School, a nurturing and
                                    innovative learning environment where students thrive
                                    academically, socially and emotionally. Our experienced
                                    teachers foster a love for learning, while our comprehensive
                                    curriculum emphasizes critical thinking and creativity. We
                                    celebrate diversity, encourage active parent involvement and
                                    provide a safe and enriching atmosphere for every child to
                                    succeed.
                                </p>
                            </div>
                            <div className="faq-wrapper mb--10">
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Vision
                                            <img
                                                src="images/newImages/gif/vision.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Inspiring lifelong learners, fostering creativity and
                                            nurturing compassionate global citizens in a safe,
                                            inclusive and innovative learning environment.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Mission
                                            <img
                                                src="images/newImages/gif/mission.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Providing a holistic and student-centered education, fostering academic excellence, character
                                            development and a passion for learning. Through innovative teaching methods and a nurturing
                                            environment, we empower students to become critical thinkers, effective communicators and
                                            responsible, compassionate contributors to society.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Ideology
                                            <img
                                                src="images/newImages/gif/ideology .gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Centered around creating a positive, stimulating and inclusive learning environment. We believe in
                                            fostering a love for learning, promoting creativity and critical thinking and nurturing the unique
                                            strengths of each student.
                                        </p>
                                    </div>
                                </div>
                                {/* <Accrodian></Accrodian> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
