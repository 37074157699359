import React from 'react'

function Index() {
    return (
        <div><section className="about-section pos-rel padding-bottom  " id="amenities">
            <div className="container">
                <div className="section-header">

                    <h3 className="title text-center pb-3" style={{ color: "#92278f" }}>AMENITIES</h3>

                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">

                    </div>
                </div>
                <div className="row justify-content-center mb-30-none -mx-10">
                    <div className="col-md-6 col-lg-5 col-xl-3">
                        <div className="feature-item" style={{ borderRadius: "15px" }}>
                            <div className="feature-thumb">
                                <img src="images/newImages/gif/paintng.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title">Art & Crafts</h5>
                                <p>
                                    Unleash creativity with our engaging art and craft activities, inspiring imagination and fostering
                                    self-expression.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-3">
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <img src="images/newImages/gif/steam1.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title">Stem Activities</h5>
                               
                                <p>
                                    Engaging hands-on tasks that stimulate curiosity and problem-solving skills, fostering creativity and
                                    learning in science.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-3">
                        <div className="feature-item" style={{ borderRadius: "15px" }}>
                            <div className="feature-thumb">
                                <img src="images/newImages/gif/new1.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title">I Gear Program</h5>
                                <p>
                                    Incentivizing positive behavior and academic achievements with a fun and motivating primary school
                                    reward program.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-3">
                        <div className="feature-item" style={{ borderRadius: "15px" }}>
                            <div className="feature-thumb">
                                <img src="images/newImages/gif/animation_lk7yuyha_small.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title">Actvie Learning </h5>
                                <p>
                                    Unlocking Creativity, Inspiring Innovation through Collaborative Problem-Solving, Critical Thinking and
                                    joyful Interactive learning.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-3">
                        <div className="feature-item" style={{ borderRadius: "15px" }}>
                            <div className="feature-thumb">
                                <img src="images/midmorning.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title">Mid Morning Snacks </h5>
                                <p>
                                    Healthy, energizing mid-morning snacks provide students with nourishment for focus and productivity in
                                    primary school.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-3">
                        <div className="feature-item" style={{ borderRadius: "15px" }}>
                            <div className="feature-thumb">
                                <img src="images/newImages/gif/iphonic.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title"> I-phonic Program </h5>
                                <p>
                                    Innovative educational platform immersing children in interactive, fun activities to develop phonics
                                    skills and enhance language proficiency.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-3 ">
                        <div className="feature-item" style={{ borderRadius: "15px" }}>
                            <div className="feature-thumb">
                                <img src="images/afterSchool_activites.gif" className="ml--8" alt="feature" />
                            </div>
                            <div className="feature-content">
                                <h5 className="title pt-3">After School Activities </h5>

                                <p>
                                    Enriching Activities, Homework Support, and Exciting Adventures, Fostering Growth, Friendship, and Fun
                                    Beyond the Classroom
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section></div>
    )
}

export default Index