import { MainState } from ".";

export const initialState: MainState = {
  loading: false,
  form: {
    name: "",
    childName: "",
    dateOfBirth: "",
    email: "",
    mobileNumber: "",
  }
};
