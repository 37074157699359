import React from "react";

function Index() {
    return (
        <div>
            {" "}
            <div
                className="teacher-section padding-bottom  pt-5 bg_img"
                data-background="images/teacher/teacher-bg.jpg"
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-header">
                                <h3 className="title my-3 text-center pb-4"
                                    style={{ color: "#92278f", }}
                                >
                                    Testimonial
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-30-none justify-content-center">
                        <div className="col-lg-6">
                            <div className="teacher-item">
                                <div className="teacher-inner">
                                    <div className="teacher-thumb">
                                        <div className="thumb-inner">
                                            <a
                                                className="view-modal pointer"
                                                data-target="#quick_view1"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img
                                                    src="images/newImages/gif/tesi122.gif"
                                                    alt="teacher"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="teacher-content">
                                        <h6 className="title">
                                            <a href="">Neha</a>
                                        </h6>
                                        <span className="info"></span>
                                        <p>
                                            Great School!!!! <br />
                                            It is an outstanding educational institution that is
                                            committed to providing high-quality education to its
                                            students. The school focuses on the holistic approach to
                                            learning ensures that each student is given the
                                            opportunity to reach their full potential.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="teacher-item">
                                <div className="teacher-inner">
                                    <div className="teacher-thumb">
                                        <div className="thumb-inner">
                                            <a
                                                className="view-modal pointer"
                                                data-target="#quick_view1"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img
                                                    src="images/newImages/gif/tesi122.gif"
                                                    alt="teacher"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="teacher-content">
                                        <h6 className="title">
                                            <a href="">Shivam</a>
                                        </h6>
                                        <span className="info"></span>
                                        <p>
                                            Excellent school in the area. My kids are studying in this
                                            school. No complaints so far. Very supportive management
                                            and staff. Spl mention to the very hard working principal,
                                            whos very kind towards all the kids and ensures that kids
                                            fall in love in going to school.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
