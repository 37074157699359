import "./App.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Carousel from "./Components/Carousel";
import AboutUs from "./Components/AboutUs";
import LeaderShip from "./Components/LeaderShip";
import Academy from "./Components/Academy";
import Amenities from "./Components/Amenities";
import WhyUs from "./Components/WhyUs";
import Testimonial from "./Components/Testimonial";
import Addmission from "./Components/Addmission";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  sliceKey as MainSliceKey,
  reducer as MainReducer,
} from "./Redux/slice";
import { MainStateRepoSaga } from "./Redux/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
function App() {
  useInjectReducer({ key: MainSliceKey, reducer: MainReducer });
  useInjectSaga({ key: MainSliceKey, saga: MainStateRepoSaga });
  return (
    <>
      <ToastContainer />
      <Navbar />
      <section >
        <Carousel />
      </section>
      <section className="pt-5 pt-md-5 pt-lg-4 mb-5">
        <AboutUs />
      </section >
      <section className="pt-5 pt-md-5 pt-lg-4 mb-5">
        <LeaderShip />
      </section>
      <section className="pt-5 pt-md-5 pt-lg-4 mb-5">
        <Academy />
      </section>
      <section className="pt-5 pt-md-5 pt-lg-4 mb-5">
        <Amenities />
      </section>
      <section className="pt-5 pt-md-5 pt-lg-4 mb-5">
        <WhyUs />
      </section>
      <section className="pt-5 pt-md-5 pt-lg-4 mb-5">
        <Testimonial />
      </section>
      <section >
        <Addmission />
      </section>
      <Footer />
    </>
  );
}

export default App;
