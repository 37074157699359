import React from "react";

function Index() {
    return (
        <div>
            <section className=" bg_img h-100" data-background="" id="top">
                <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <div className="carousel-inner" data-interval="20">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-6"></div>
                            </div>
                            <img
                                src="images/newImages/landingpage/discoveryocus1.jpg"
                                alt=""
                                className="img-fluid w-100 "
                                style={{ borderRadius: "25px" }}
                            />
                            <div className="custom-buttondiv"></div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-6"></div>
                                <div className="col-6 rightImage1 square"></div>
                            </div>
                            <img
                                src="images/newImages/landingpage/newImages1.jpg"
                                alt=""
                                className="img-fluid w-100 "
                                style={{ borderRadius: "25px" }}
                            />
                            <div className="custom-buttondiv1"></div>
                        </div>
                        <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                        ></a>
                        <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                        ></a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Index;
