import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectLoading, selectForm } from "../../Redux/selector";
import { actions } from "../../Redux/slice";
import { toast } from "react-toastify";

function Index() {
    const dispatch = useDispatch();
    const form = useSelector(selectForm);
    const loading = useSelector(SelectLoading);

    const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        dispatch(actions.updateFormValue({ key: name, value: value }));
    };
    const handleEmailFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        dispatch(
            actions.updateFormValue({ key: name, value: value.toLowerCase() })
        );
    };
    const handleSignin = () => {
        dispatch(
            actions.updateFormValue({
                key: "email",
                value: form?.email?.trim(),
            })
        );

        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (form.email.length > 0) {
            if (!regex.test(form.email)) {
                toast.error("please enter valid email");
                return;
            }
        }
        dispatch(
            actions.doSendEmail({
                callback() {
                    dispatch(actions.clearFrom());
                },
            })
        );
    };
    return (

        <div className="padding-top about-bottom" id="addmission">
            <div className="container">
                <div className="section-header">
                    <h3
                        className="title text-center pb-3"
                        style={{ color: "#92278f" }}

                    >
                        ADMISSIONS
                    </h3>
                </div>
                <div className="square2"></div>
                <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="square21 d-block d-lg-none"></div>

                        <div className="square penRight d-lg-none">
                            <img
                                src="images/newImages/pen.png"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                        <div className=" pr-4 text-center text-lg-start ">
                            <img
                                src="images/admission.png"
                                alt="about"
                                className="w-75 pt-5"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="square penRight d-none d-lg-block">
                                <img
                                    src="images/newImages/pen.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="faq-wrapper mb--10">
                                <div className="">
                                    <form className="contact-form" >
                                        <div className="form-group  w-100">
                                            <input
                                                type="text"
                                                placeholder="Your Name"

                                                name="name"
                                                value={form.name}
                                                onChange={handleFieldChange}
                                                style={{ borderRadius: "25px" }}
                                            />
                                        </div>
                                        <div className="form-group  w-100">
                                            <input
                                                type="text"
                                                placeholder="Child Name"

                                                name="childName"
                                                value={form.childName}
                                                onChange={handleFieldChange}
                                                style={{ borderRadius: "25px" }}
                                            />
                                        </div>
                                        <div className="form-group  w-100">
                                            <input
                                                type="date"
                                                placeholder="Child DOB"

                                                name="dateOfBirth"
                                                value={form.dateOfBirth}
                                                onChange={handleFieldChange}
                                                style={{ borderRadius: "25px" }}
                                            />
                                        </div>
                                        <div className="form-group  w-100">
                                            <input
                                                type="text"
                                                placeholder="Your Email"

                                                name="email"
                                                value={form.email}
                                                onChange={handleEmailFieldChange}
                                                style={{ borderRadius: "25px" }}
                                            />
                                        </div>
                                        <div className="form-group w-100">
                                            <input
                                                type="number"
                                                placeholder="Phone"

                                                name="mobileNumber"
                                                value={form.mobileNumber}
                                                onChange={handleFieldChange}
                                                style={{ borderRadius: "25px" }}
                                            />
                                        </div>
                                        <div className="form-group w-100 text-center">
                                            <button
                                                className="custom-button w-50 py-0"
                                                style={{ background: "none", border: 0 }}
                                                onClick={handleSignin}
                                                type="button"
                                            >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                        <div className="square bagleft">
                                            <img
                                                src="images/newImages/bag.png"
                                                className="img-fluid float-right"
                                                alt=""
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="square21 d-none d-lg-block"></div>
            </div>
        </div>

    );
}

export default Index;
