import React from "react";


function Index() {
    return (
        <div className="padding-top about-bottom padding-bottom" id="academics">
            <div className="container" id="academics">
                <div className="square2 "></div>
                <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className=" pr-4 square">
                            <img
                                src="images/newImages/newAcademics.png"
                                alt="about"
                                className="img-fluid "
                            />
                        </div>
                        <div className="square21 d-block d-lg-none"></div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header left-style mb-olpo">
                                <h3 className="title pb-3" style={{ color: "#92278f" }}>
                                    Academics
                                </h3>
                                <p>
                                    We are committed to providing a comprehensive and enriching academic program that prepares our students
                                    for a successful future. Our curriculum is thoughtfully designed to meet the diverse needs and interests
                                    of our young learners, nurturing their intellectual curiosity and fostering a love for learning.
                                </p>
                            </div>
                            <div className="faq-wrapper mb--10">
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Curriculum
                                            <img
                                                src="images/newImages/gif/1.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Comprehensive and well-structured educational program that emphasizes a balanced approach to
                                            academic.
                                            Learning, incorporating core subjects, co-curricular activities, and life skills development.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Program
                                            <img
                                                src="images/newImages/gif/2.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Activities at our school provide hands-on learning experiences that encourage creativity, critical
                                            thinking and problem-solving. Through engaging projects and interdisciplinary approaches, students
                                            explore real-world challenges and develop essential skills for future success in diverse fields.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                           Collaborative Learning
                                            <img
                                                src="images/newImages/gif/3.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Centered around creating a positive, stimulating and inclusive learning environment. We believe in
                                            fostering a love for learning, promoting creativity and critical thinking and nurturing the unique
                                            strengths of each student.
                                        </p>
                                    </div>
                                </div>
                                {/* <Accrodian></Accrodian> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
